import React from 'react'
import { useDispatch } from 'react-redux'
import { openAllGiftModals } from '../../redux/communityReducers/GiftReducer'
import * as styles from './social.module.css'

const AllGiftBtn = ({ num, recentImg, inspirationId }) => {
  const dispatch = useDispatch()

  let img =
    recentImg.length === 0
      ? ' '
      : recentImg.map((items, index) => {
          return (
            <img
              loading="lazy"
              width="30px"
              height="30px"
              src={items}
              key={index}
              className={styles.recentImg}
              alt=""
            />
          )
        })

  return (
    <div
      className={styles.allgiftBtn}
      onClick={() =>
        dispatch(openAllGiftModals({ num, userInsId: inspirationId }))
      }
    >
      <div className={styles.allimg}>{img}</div>
      <span>{num}</span>
    </div>
  )
}

export default AllGiftBtn
