import { useEffect, useState } from 'react'
import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT
} from '../constants/MobileConstants'

export const useIsTablet = () => {
  const [isTablet, setIsTablet] = useState(false)

  useEffect(() => {
    setIsTablet(
      document.body.clientWidth <= TABLET_BREAKPOINT &&
        document.body.clientWidth > MOBILE_BREAKPOINT
    )

    const listener = () => {
      setIsTablet(
        document.body.clientWidth <= TABLET_BREAKPOINT &&
          document.body.clientWidth > MOBILE_BREAKPOINT
      )
    }

    window.addEventListener('resize', listener)

    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  return isTablet
}
