import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  height: 80px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  zoom: ${props => props.zoom};

  @media (max-width: 800px) {
    height: 60px;
    padding-left: 8px;
    padding-right: 8px;
  }
`

export const HeaderLeftPart = styled.div`
  display: flex;
  align-items: center;
`

export const AuthorInfoContainer = styled.div`
  float: left;
  margin-right: 10px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;

  @media only screen and (max-width: 600px) {
    width: 80%;
    margin-left: 10px;
    height: auto;
  }
`

export const AuthorName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #000000;

  @media only screen and (max-width: 600px) {
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) {
    display: flex;
    margin-top: 20px;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const DotDivider = styled.div`
  border-radius: 50%;
  margin-left: 7px;
  width: 6px;
  height: 6px;
  background: #000000;
  display: inline-block;
  vertical-align: middle;
`

export const FollowButton = styled.button`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #ff5e6d;
  border: none;
  padding: 0;
  margin-left: 12px;
  display: inline-block;
  background: transparent;

  ${props => props.active && `color: #8b8b8b; margin-left: 8px;`}

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`

export const PostDate = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #8b8b8b;
  text-overflow: ellipsis;
  width: 400px;

  @media only screen and (max-width: 600px) {
    font-size: 10px;
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    display: none;
  }
`

export const RedesignButton = styled.button`
  outline: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  background: #ff5e6d;
  border: 2px solid #ff5e6d;
  border-radius: 180px;
  height: 40px;
  min-width: 124px;
  transition: all 0.5s ease;
  cursor: pointer;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
  flex-grow: 1;
  flex-shrink: 0;
  margin-right: 10px;

  @media screen and (max-width: 600px) {
    font-size: 12px;
    height: 34px;
    min-width: 100px;
  }
`

export const JoinedButton = styled(RedesignButton)`
  color: #000;
  background: #e5e5e5;
  border: 2px solid #e5e5e5;
  cursor: default;
`

export const MenuButton = styled.div`
  & > div > svg > circle {
    fill: black;
  }

  & > div:nth-child(2) {
    left: 593px;
    top: 120px;
  }

  @media only screen and (max-width: 786px) {
    & > div:nth-child(2) {
      left: unset;
      right: 0px;
      top: 80px;
    }
  }

  @media only screen and (max-width: 600px) {
    & > div:nth-child(2) {
      left: unset;
      right: 0px;
      top: 80px;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    & > div:nth-child(2) {
      left: 520px;
      top: 120px;
    }
  }
`

export const ChallengeModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  overflow: auto;
`

export const ChallengeModalDimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`

export const ChallengeModal = styled.div`
  position: relative;
  width: 100%;
  max-width: min-content;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  justify-content: center;
  margin-top: 10vh;
  margin-bottom: 20vh;
  padding: 0 24px 20px;
  overflow: visible;

  @media (max-width: 1100px) {
    margin-top: 0;
    margin-bottom: 0;
    max-width: 100vw;
    padding-top: 30px;
    padding-bottom: 50px;
  }
`

export const ChallengeViewCloseButton = styled.button`
  position: sticky;
  border: none;
  top: 10px;
  left: 100%;
  transform: translateX(60px);
  background: none;
  cursor: pointer;

  @media (max-width: 1100px) {
    z-index: 1000;
    right: 30px;
    top: 10px;
    filter: invert(1);
    transform: translateX(0);
  }
`

export const RightPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`
