import React from 'react'
import arrowRightIcon from '../../assets/arrow-right.svg'
import filterIcon from '../../assets/filterIcon.svg'
import {
  InspirationFilterArrow,
  InspirationFilterIcon,
  InspirationFilterTag
} from './Feed.styles'

export const AnimatedFilterTag = ({
  post: { animationInfo },
  onClick,
  mode
}) => {
  return (
    <InspirationFilterTag mode={mode} onClick={onClick}>
      <InspirationFilterIcon src={filterIcon} alt="Filter" />
      <span>{animationInfo.title}</span>
      <InspirationFilterArrow src={arrowRightIcon} alt="" />
    </InspirationFilterTag>
  )
}
