import Link from 'next/link'
import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { gotoLogIn } from '../../../redux/actions/AuthActions'
import { FollowAction, unFollowAction } from '../../../redux/actions/FollowingActions'
import { setLocationState } from '../../../redux/designerReducers/locationStateReducer'
import ProfileImage from '../../../routes/Profile/components/profile/ProfileImage'
import formatDate from '../../../utils/formatDate'
import { getImageSource } from '../../../utils/getImageSource'
import { getUsername } from '../../../utils/getUsername'
import { getHasJoinedChallenge, getHasJoinedUserEvent, getMyChallengeStatus, getMyUserEvents, getUserEventsStatus } from '../../../redux/apis'
import { convertDataToArtboard, getUserToken } from '../../../utils/fetchUtils'
import InspirationActionsMenu from '../../InspirationActionsMenu'
import { AuthorInfoContainer, AuthorName, DotDivider, FollowButton, HeaderLeftPart, HeaderWrapper, MenuButton, PostDate, RedesignButton, JoinedButton, ChallengeModalWrapper, ChallengeModalDimmer, ChallengeModal, ChallengeViewCloseButton, RightPart } from './DesignHeader.styles'
import { useRouter } from 'next/router'
import { useQuery } from 'react-query'
import Portal from '../../DesignerCommon/portal'
import Challenge from '../../../routes/Challenge'
import { EventDetailView } from '../../EventDetailView/EventDetailView'
import closeIconImg from '../../../assets/blog/blogClose.svg'
import { useDecor } from '../../../context/decor'

export const DesignHeader = ({ data, id, savedList, inspirationType, hideActionButton, showCaptionModal }) => {
  const { eventStatuses } = useDecor()
  const isMobile = useIsMobile()
  const [showChallengeModal, setShowChallengeModal] = useState(false)
  const isMagazine = inspirationType === 'pgc'
  const dispatch = useDispatch()
  const { following } = useSelector(state => state.follows)
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const me = useSelector(state => state.profile.me)
  const designerId = data.user.objectId
  const followState = useMemo(() => {
    return following && following.includes(data.user.objectId) ? true : false
  }, [following, data.user.objectId])
  const participationStatus = useSelector(state => state?.challenge?.status)
  const router = useRouter()

  const { data: joinedUserEvents } = useQuery(['PROFILE_MY_EVENTS'], async () => {
    const { userEvents } = await getMyUserEvents({
      statuses: ['active', 'draft', 'submitted', 'incomplete', 'winnerSelection', 'closed', 'archived']
    })

    return userEvents || {}
  })

  const followClick = useCallback(
    e => {
      e.stopPropagation()
      e.preventDefault()
      if (followState) {
        dispatch(unFollowAction(designerId))
      } else {
        dispatch(FollowAction(designerId))
      }
    },
    [followState, designerId, dispatch]
  )

  const logInCheck = e => {
    if (!isAuthenticated) {
      e.preventDefault()
      dispatch(gotoLogIn())

      return false
    }
  }

  const isChallengeExpired = useMemo(() => {
    let newDate = new Date()

    if (data?.designContest) {
      newDate = new Date(data?.designContest?.expiresAt?.iso)
    } else if (data?.expiresAt) {
      newDate = new Date(data?.expiresAt?.iso)
    }

    return newDate < new Date()
  }, [data])

  const challengeId = data?.userEvent?.objectId || data?.designContest?.objectId

  const [isJoined, setJoined] = useState(false)
  /*
//OLD 2024
  useEffect(() => {
    if (
      participationStatus?.joined?.includes(challengeId) ||
      participationStatus?.started?.includes(challengeId)
    ) {
      setJoined(true)

      return
    }

    const checkIsJoined = async () => {
      if (!challengeId) return
      
      //console.log("GOT HERE", participationStatus, challengeId, joinedUserEvents)
      
      const status = await getMyChallengeStatus()
      const userEventStatus = await getUserEventsStatus()
      const hasJoinedCurrentEvent = await getHasJoinedUserEvent(challengeId)
      const didJoinUserEvent = await getHasJoinedChallenge(challengeId)

      if (
        hasJoinedCurrentEvent?.didUserJoin ||
        hasJoinedCurrentEvent?.didUserStart ||
        didJoinUserEvent?.didUserJoin ||
        didJoinUserEvent?.didUserStart
      ) {
        setJoined(true)

        return
      }

      if (
        joinedUserEvents &&
        Array.isArray(joinedUserEvents) &&
        joinedUserEvents?.find(event => event.objectId === challengeId)
      ) {
        setJoined(true)

        return
      }

      if (!status || !userEventStatus) return

      const joined = [...status.joined, ...userEventStatus.joined]
      const started = [...status.started, ...userEventStatus.started]

      if (joined.find(joinedId => joinedId === challengeId)) {
        setJoined(true)

        return
      }
      if (started.find(startedId => startedId === challengeId)) {
        setJoined(true)

        return
      }
      setJoined(false)
    }

    
    if (!getUserToken()) {
      setJoined(false)
      return
    }
    
    // IN PROGRESS
    // moving get joined list from getMe1 call
    checkIsJoined()


  }, [participationStatus, challengeId, joinedUserEvents])
*/

  useEffect(() => {
    if (!getUserToken()) {
      setJoined(false)
      return
    }

    if (participationStatus?.joined?.includes(challengeId) || participationStatus?.started?.includes(challengeId)) {
      setJoined(true)
      return
    }

    const checkIsJoined = async () => {
      if (!challengeId) return

      if (joinedUserEvents && Array.isArray(joinedUserEvents) && joinedUserEvents?.find(event => event.objectId === challengeId)) {
        setJoined(true)
        return
      }

      if (eventStatuses.joined[challengeId]) {
        setJoined(true)
        return
      }

      if (eventStatuses.started[challengeId]) {
        setJoined(true)
        return
      }

      setJoined(false)
    }

    checkIsJoined()
  }, [participationStatus, challengeId, joinedUserEvents, eventStatuses])

  const designButtonLabel = useMemo(() => {
    if ((data?.designContest || data?.expiresAt) && !isChallengeExpired) return 'Join Now'

    return 'Redesign'
  }, [isChallengeExpired, data])

  const actionButton = useMemo(() => {
    if (!isChallengeExpired && (data?.designContest || data?.userEvent)) {
      return (
        <RedesignButton
          onClick={() => {
            setShowChallengeModal(true)
          }}
        >
          Check {data?.designContest ? 'Challenge' : 'Event'}
        </RedesignButton>
      )
    }

    return (
      (!!data?.designContest || !!data?.idea) &&
      !isMagazine &&
      (isJoined ? (
        <JoinedButton>Joined</JoinedButton>
      ) : (
        <RedesignButton
          onClick={() => {
            if (!isAuthenticated) {
              dispatch(gotoLogIn())

              return
            }

            if ((data?.designContest || data?.expiresAt) && !isChallengeExpired) {
              dispatch(
                setLocationState({
                  idea: convertDataToArtboard(data.designContest.objectId, data.designContest.contestImageFile.url, []),
                  type: 'challenge',
                  markers: data.designContest.buttons,
                  requirements: data.designContest?.specialRequirements?.tasks || [],
                  challengeId: data.designContest.objectId
                })
              )

              router.push(`/create/challenge/${data.designContest.objectId}`)
            } else if (data?.idea) {
              dispatch(
                setLocationState({
                  idea: convertDataToArtboard(data.idea.objectId, data.idea.cfBgImageUrl, data.idea.productsWithPosition),
                  type: 'redesign',
                  markers: [],
                  requirements: [],
                  challengeId: data.objectId
                })
              )

              router.push(`/redesign/${data?.idea?.objectId}`)
            }
          }}
        >
          {designButtonLabel}
        </RedesignButton>
      ))
    )
  }, [data, isMagazine, isJoined, isAuthenticated, isChallengeExpired, designButtonLabel])

  return (
    <>
      <HeaderWrapper>
        <HeaderLeftPart>
          <Link href={isAuthenticated ? `/dm/${designerId}` : '/'}>
            <ProfileImage onClick={logInCheck} clickable user={data.user} size={isMobile ? 33 : 64} showLevel={false} small />
          </Link>
          <AuthorInfoContainer>
            <AuthorName>
              <Link onClick={logInCheck} legacyBehavior={false} href={isAuthenticated ? `/dm/${designerId}` : '/'}>
                {getUsername(data.user)}
              </Link>
              {(!me || me.objectId !== designerId) && (
                <>
                  <DotDivider />
                  <FollowButton active={followState} onClick={followClick}>
                    {followState ? 'Followed' : 'Follow'}
                  </FollowButton>
                </>
              )}
            </AuthorName>
            <PostDate>{formatDate(data.createdAt)}</PostDate>
          </AuthorInfoContainer>
        </HeaderLeftPart>
        <RightPart>
          {!hideActionButton && actionButton}
          <MenuButton>
            <InspirationActionsMenu fromOwner={data?.user?.objectId === me?.objectId} dark inspirationId={id} location="home" imageUrl={getImageSource(data).src} type={data.type} savedList={savedList} showCaptionModal={showCaptionModal} />
          </MenuButton>
        </RightPart>
      </HeaderWrapper>
      {showChallengeModal && (
        <Portal>
          <ChallengeModalWrapper>
            <ChallengeModalDimmer onClick={() => setShowChallengeModal(false)} />
            <ChallengeModal>
              <ChallengeViewCloseButton
                onClick={() => {
                  setShowChallengeModal(false)
                }}
              >
                <img src={closeIconImg} alt="Close" />
              </ChallengeViewCloseButton>
              {data?.designContest && <Challenge challengeId={data?.designContest?.objectId} hideBackButton={true} />}
              {data?.userEvent && <EventDetailView eventId={data?.userEvent?.objectId} hideBackButton={true} />}
            </ChallengeModal>
          </ChallengeModalWrapper>
        </Portal>
      )}
    </>
  )
}
