import React from 'react'
import GiftBtn from './GiftBtn'
import LikeBtn from './LikeBtn'
import AllGiftBtn from './AllGiftBtn'
import CommentBtn from './CommentBtn'
import * as styles from './socialcontainer.module.css'

const SocialContainer = ({ id, numLikes, data, color, loc, numComments }) => {
  const numGifts = data === undefined ? 0 : data.numGifts
  const recentVGImageUrls = data === undefined ? '' : data.recentVGImageUrls

  const socialContainer =
    numGifts !== undefined ? (
      <div className={styles.socialContainer}>
        <AllGiftBtn
          inspirationId={id}
          num={numGifts}
          recentImg={recentVGImageUrls}
        />
      </div>
    ) : null

  return (
    <div className={styles.mediaContainer}>
      <div className={styles.shareMediaContainer}>
        <LikeBtn
          postId={id}
          numLikes={numLikes}
          displayType="primary"
          loc={loc}
        />
        <CommentBtn
          postId={id}
          data={data}
          commentsCount={numComments || data.numComments || '0'}
          displayType="primary"
          loc={loc}
        />
        <GiftBtn
          postId={id}
          numGifts={data.numGifts}
          color={color}
          displayType="primary"
          loc={loc}
        />
      </div>
      {socialContainer}
    </div>
  )
}

export default SocialContainer
